.section-media {
    margin-left: 20px;
    margin-top: 150px;
}

.heart-fill {
    fill: #EF3652;
}

.name-artist {
    color: #FFF !important;
    font-size: 37px !important;
    font-weight: 700 !important;
    margin-left: 30px !important;
}

.svg-adjust {
    display: flex;
    gap: 13px;
    margin-left: 25px;
}

.table-timer-action {
    justify-content: end;
}

.table-sng-color {
    color: var(--white-text) !important
}

.mnu-txting {
    color: #fff !important;

}

// .mnu-color-list {
//     width: 300px !important;
//     background: var(--profile-modal) !important;
//     color: var(--white-text) !important;
//     border: var(--jazz-tune-modal-border) !important;
//     border-radius: 20px !important;
// }

.table-speration {
    border-collapse: separate !important;
    border-spacing: 0px 10px !important;
}

.font-color-table {
    color: #FFF;
}

.gradient {
    background: linear-gradient(180deg, rgba(19, 21, 22, 0.50) 0.09%, #131516 51.68%), lightgray 0px -5.273px / 100% 105.646% no-repeat;
    filter: blur(25px);
    position: absolute;
    left: 0;
    top: 0;
}

.background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(25px);
    z-index: -20;
}

.background-image:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    filter: blur(25px);
    background: linear-gradient(180deg, rgba(19, 21, 22, 0.50) 0.09%, #131516 51.68%);
}

.light-theme .background-image:after {
    background: linear-gradient(180deg, rgba(19, 21, 22, 0.50) 0.09%, #fff 51.68%);
}


.track-timer-re {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    min-width: 100px;
    margin-right: 67px;

}

.svg-gap {
    display: flex;
    gap: 12px;
}


.text-color {
    margin-left: 28px;
    display: flex;
    gap: 15px;
    color: #fff;
    font-size: 19px;
    margin-bottom: 14px;
    font-family: var(--font-Urbanist) !important;

}

.media-sub-txt {
    // padding-left: 30px;
}

.artist-name {
    color: var(--light, #EFEEE0);
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: normal;
    text-transform: capitalize;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
    font-family: var(--font-Urbanist) !important;

}

.track-player-btn {
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(5.5px);
    padding: 5px 15px;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    color: var(--white-text);
    font-size: 12px;
    display: inline-flex;
}

.btn-media {
    display: inline-flex;
    height: 38px;
    padding: 8px 22px;
    align-items: center;
    gap: 10px;
}

.play-all-btn {
    background-color: #fff;
    padding: 10px 50px;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    font-size: 15.4px;
}

.header-icons {
    border-radius: 50px;
    width: 80%;
    cursor: pointer;
}

.track-head-btn {
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(5.5px);
    padding: 7px 20px;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    color: #fff;
    font-size: 15.4px;
    display: inline-flex;
}

.icon-span {
    padding-right: 10px;
}

.icon-span-text {
    padding-top: 4px;
}

.track-menu {
    color: #fff !important;
}

.table-row-track {
    background: #1c1d1e;
}

// .btn-media button {
//     border-radius: 41px;
//     padding: 6px 13px;
//     border: 0.839px solid rgba(255, 255, 255, 0.05);
//     background: rgba(255, 255, 255, 0.05);
//     color: #FFF;
//     text-align: center;
//     font-size: 16px;
//     font-weight: 700;
// }

// .light-theme .name-artist {
//     background-color: white;
//     color: black !important;
// }

.track-table-main {
    flex-flow: row;
    // align-items: center;
}

.track-detail-table-title {
    // color: #FFF;
    color: var(--white-text);
    font-size: 16.859px;
    // font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
    cursor: pointer;
    font-family: "Syne", sans-serif !important;
}

.song-artist {
    // color: #B0B8BF;
    color: var(--table-text-artist);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-Urbanist) !important;
}

.track-detail-table span {
    display: block;
}

.track-thumb-table {
    min-width: 56px;
    margin-right: 18px;
    cursor: pointer;
}

.audio-player-main {
    height: 110px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    border: var(--player-border);
    // background: rgba(29, 33, 35, 0.9);
    background: var(--player-bg);
    -webkit-backdrop-filter: blur(15px);
}

.audio-player-bottom {
    background: transparent;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0px;
    position: relative;
}

.audio-player-bottom .rhap_container {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

}

.audio-player-bottom .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
}

.audio-player-bottom .rhap_stacked .rhap_controls-section {
    margin-top: -15px !important;
}

.audio-player-bottom .rhap_progress-section {
    width: 53%;
    position: absolute;
    right: 20%;
    top: 50px;
    bottom: 10%;
}

.audio-player-bottom .rhap_container {
    padding: 0px !important;
    height: 100px !important;
}

.audio-player-bottom .rhap_additional-controls {
    flex: 0 0 auto;
}

.audio-player-bottom .rhap_main-controls-button,
.audio-player-bottom .rhap_play-pause-button {
    width: auto;
    height: auto;
}

.audio-player-bottom .rhap_main-controls {
    margin-left: 30%;
    padding-bottom: 20px;
}

.thumbnailouter-track-audio {
    display: flex;
    align-items: center;
    width: 193px;
}

.shuffle-icon {
    position: absolute;
    left: 37%;
    top: 26%;
}

.repeat-icon {
    position: absolute;
    left: 61%;
    top: 26%;
    z-index: 100;
}

.thumbnail-track-audio {
    min-width: 57px;
    width: 57px;
    height: 57px;
    margin-right: 14px;
}

.detail-track-audio {
    margin-right: 10%;
}

.detail-track-audio span {
    display: block;
    color: var(--white-text);
    font-size: 15px;
    font-weight: 700;
    line-height: normal;
    font-family: "Syne", sans-serif !important;
    width: 108px;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis !important;
    text-transform: capitalize;
    word-break: break-word;
}

.detail-track-audio span:last-child {
    color: #9CA3AF;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    font-family: var(--font-Urbanist) !important;

}

.player-tune {
    position: absolute;
    left: 15%;
    // top: 20px;
}

.three-dots {
    cursor: pointer;
    color: #FACD66 !important;
    position: absolute;
    left: 24%;
    bottom: 46%
}

.rhap_forward-button,
.rhap_rewind-button {
    display: none;
}

.thumbnail-track-audio img {
    object-fit: cover;
    width: 62px;
    border-radius: 17px;
}

.audio-player-bottom .rhap_repeat-button {
    margin-left: 6px;
}

.audio-player-bottom .rhap_main-controls-button {
    margin: 35px 40px;
}

.audio-extra-button {
    margin-right: 10px;

}

.audio-extra-button span {
    margin: 0px 5px;

}

.rhap_volume-container {
    margin-right: 60px !important;
}

.audio-player-bottom .rhap_container svg {
    vertical-align: initial;
    color: white;
}

.rhap_rewind-button svg path,
.rhap_forward-button svg path,
.rhap_skip-button svg path {
    fill: var(--player-button) !important;
}

.menu-icon-player svg path {
    fill: var(--white-text) !important;
}

// .audio-player-bottom .rhap_volume-bar {
//     background-color: #EF3652;
// }
.audio-player-bottom .rhap_volume-filled {
    // background-color: #EF3652;
    background: var(--secondary, #FACD66);
}

.audio-player-bottom .rhap_time {
    color: var(--white-text);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.audio-player-bottom .rhap_volume-button {
    color: white;
}

.audio-player-bottom .rhap_progress-filled {
    // background-color: #EF3652;
    background: var(--secondary, #FACD66);

}

.back-colr-img {
    background-color: rgba(255, 255, 255, 0.0) !important;
}

.table-thm-bml {
    min-width: 39px;
}

.table-thm-bml img {
    width: 39px;
    height: 39px;
    object-fit: cover;
}

.table-for-mobile {
    display: none;
}

.media-sec-for-mobile {
    display: none;
}

.player-for-mobile {
    display: none;
}



@media(max-width:475px) {
    .new-sub-jazztune {
        display: block;
    }

}

@media(max-width:1199px) {
    .table-for-full-screen {
        display: none;
    }

    .media-sec-mobile-play {
        width: 100%;
        height: 293px;
        border-radius: 35px;
        text-align: center;
        margin: 0px auto;
    }

    .media-sec-mobile-play:before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;

        border-radius: 30px;
        background: linear-gradient(180deg, rgba(19, 21, 22, 0.00) 0%, #131516 100%);
    }

    .header-icons {
        width: 80%;
    }

    .for-mob-media {
        display: flex;
        justify-content: space-between;
    }

    .abbsolute-media {
        position: absolute;
        bottom: 0px;
        width: calc(100% - 40px);
        left: 20px;

    }

    .artist-name-mobile {
        color: var(--light, #EFEEE0);
        font-size: 14px !important;
        font-weight: 400 !important;
        text-transform: capitalize;
        text-align: center;
    }

    .media-sec-font {
        text-align: center;
        color: hsl(0, 0%, 100%);
        font-size: 18px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .text-mobile {
        color: rgba(239, 238, 224, 0.50);
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 120% !important;
        text-align: center;
        /* 14.4px */
    }

    .player-for-full-screen {
        display: none;
    }

    .audio-player-main {
        border: none !important;
        background: none !important;
    }

}

@media(max-width:1199px) {

    .table-for-mobile,
    .media-sec-for-mobile {
        display: block;
    }


    .media-sec-full-screen {
        display: none;
    }

    .player-for-mobile {
        display: block;
    }

    .audio-player-bottom .rhap_progress-container {
        display: none;

    }

    .audio-player-bottom .rhap_progress-section {
        display: none;

    }

    .audio-player-bottom .rhap_container svg {
        // display: none;
    }

    .audio-player-mobile {
        border-radius: 44px;
        border: 1px solid rgba(79, 82, 79, 0.32);
        background: rgba(26, 30, 31, 0.60);
        backdrop-filter: blur(7.5px);
    }

    // .audio-player-main {
    //     width: 326px !important
    // }
    .audio-player-bottom {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .thumbnailouter-track-audio {
        margin-left: 20px;
        margin-top: -7px;

    }

    .audio-player-bottom .rhap_main-controls {
        // margin-left: 0px;
        margin-left: 14% !important;
        padding-bottom: 0px;
    }

    .audio-player-bottom .rhap_volume-bar-area,
    .rhap_volume-controls,
    .rhap_rewind-button,
    .rhap_forward-button {
        display: none;
    }

    .audio-player-bottom .rhap_main-controls-button {
        margin: 43px 40px;
    }
}

@media(max-width:1366px) {
    .player-tune {
        left: 16%;
        // top: 20px;
    }

    .three-dots {
        left: 31.5%;
    }

    .audio-player-bottom .rhap_main-controls {
        // margin-left: 26% !important;
        margin-left: 14% !important;
    }


}

@media(max-width:1566px) {
    .three-dots {
        left: 30%;
    }


}