.profile-div {
    position: fixed;
    top: 120px;
    right: 20px;
    z-index: 200;
    padding: 22px 18px 22px 18px;
    box-sizing: border-box;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    // background: rgba(29, 33, 35, 0.30);
    background: var(--profile-modal);
    backdrop-filter: blur(15px);
    cursor: pointer;
    width: 300px;
}

.profile-list-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-list-main button {
    border-radius: 47px;
    padding: 7px 22px;
    border: 1px solid rgba(176, 184, 191, 0.20) !important;
    background: #EF3652;
    // border: none;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.account-text h2 {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.list-menu-pad {
    margin-left: -16px;
}

.social-gap {
    margin-left: 5px;

}

.socail-svg-main {
    margin-left: -6px;
}

.profile-inner-text h2 {
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 4px
}

.profile-inner-text p {
    color: rgba(176, 184, 191, 0.60);
    font-size: 13px;
    font-weight: 500;
    margin: 0px;

}

.profile-inner-social h2 {
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 15px;
    margin-bottom: 10px
}

.profile-list-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.profile-list-social button {
    padding: 7px 9px;
    color: #FFF;
    border-radius: 47px;
    border: 0.5px solid #EF3652;
    background: rgba(255, 255, 255, 0.05);
}

.theme-main-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.theme-main-inner h2 {
    margin: 0px;
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
}

.theme-main-inner p {
    margin-top: 5px;
    color: rgba(176, 184, 191, 0.60);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
}

.personalize-heading-text h2 {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.list-div-padd {
    margin-left: -66px;
}

.about-main h2 {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.about-main-inner {
    // color: #FFF;
    color: var(--white-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    padding: 10px 10px;
}

.profile-line-txt {
    padding: 3px 12px;
}

.delete-icon {
    text-align: center;
}

.modal-div h2 {
    text-align: center;
    color: white;
}

.modal-div p {
    text-align: center;
    color: var(--white-text);
}

.logout-btn-profile {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #EF3652;
    padding: 8px 30px;
    color: white;
}

.logout-btns {
    display: flex;
    justify-content: space-between;
}

.logout-btns .logout-btn-profile-no {
    border-radius: 15px;
    background: #EF3652;
    border: 1px solid #EF3652;
    padding: 8px 30px;
    color: white;
}

.logout-btns .logout-btn-profile-yes {
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #EF3652;
    padding: 8px 30px;
    color: white;

}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 17px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 4;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

.profile-svgs {
    margin-left: auto;
}

input:checked+.slider {
    background-color: #EF3652;
}

input:focus+.slider {
    box-shadow: 0 0 1px #EF3652;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media (min-width:1280px) and (max-width:1439px) {
    .modal-div p {
        font-size: 12px !important;
    }
}