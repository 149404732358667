a {
    text-decoration: none;
}

.login-input-number {
    background: #1A1E1F !important;
    width: 100% !important;
    border-radius: 13px !important;
    color: #fff !important;
    padding: 10px !important;
    font-size: 14px !important;
}

.inpt-otp-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.login-input-code input {
    background: #1A1E1F !important;
    width: 66px !important;
    border-radius: 13px !important;
    color: #fff !important;
    font-size: 20px !important;
    border: 1px solid transparent !important;
    outline: none !important;
    padding: 20px !important;
    height: 66px;
}

.login-input-code {
    border-radius: 13px !important;
    color: #fff !important;
    font-size: 20px !important;
    margin-right: 12px;
}

.inpt-otp-center .login-input-code:last-child {
    margin-right: 0px;
}

.login-input-code input:focus {
    background: #131516 !important;
    border-radius: 13px !important;
    color: #fff !important;
    font-size: 20px !important;
    border: 1px solid red !important;
}

.continue-btn-outer {
    text-align: center;
}

.continue-btn-inner {
    background-color: #D71920;
    border-radius: 30px;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid #D71920;
    cursor: pointer;
}

.login-header-txt {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.modal-logo {
    text-align: center;
}

.modal-logo-img {
    width: 100%;
    margin-top: 15px;
}

.modal-logo-main {
    padding-top: 210px !important;
}

.modalImage {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0px 25px;
    top: -25%;
}

.modalImageNew {
    width: 100%;
    padding: 0px 5px;
    border-radius: 30px;
}

.cnfrm-txt {
    color: var(--white-text);
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    font-family: var(--font-Urbanist) !important;
    margin-bottom: 20px;
}

.conf-msg-txt {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.unsubscribe {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}

.sub-btn-main {
    display: flex;
    margin: 15px 0px;
    justify-content: center;
}

.sub-btn-yes {
    border-radius: 19.17px;
    background: #D71920;
    border: none;
    padding: 10px 40px;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
}

.subscription-loading {
    margin-left: 5px;
    color: #FFFFFF !important
}

.sub-btn-no {
    border-radius: 19.17px;
    border: 1px solid #fff;
    background: #131516;
    padding: 10px 40px;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
}

.charge-txt {
    color: var(--white-text);
    text-align: center;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    text-transform: capitalize;
    font-family: var(--font-Urbanist) !important;
}

.or-txt {
    color: var(--white-text);
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: var(--font-Urbanist) !important;
}

.dial-txt {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(29, 33, 35, 0.30);
    backdrop-filter: blur(15px);
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 13px;
    width: 105%;
}

.dial-txt span {
    color: #fff;
    font-weight: bold;
}

.modal-logo-img-1 {
    width: 35%;
    position: absolute;
    left: 15%;
    top: 24%;
}

.modal-logo-img-2 {
    width: 40%;
    margin-top: 15px;
}

.text-sign-success-1 {
    font-size: 21px;
}

.text-sign-success-2 {
    font-size: 12px;
}

.pin-text {
    font-size: 20px;
}

.close-modal {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50px;
    color: #000;
    position: absolute;
    top: 9%;
    left: 49%;
    cursor: pointer;
    z-index: 10;
}

.thanks-icon-subscribe {
    top: -190px;
    left: 80px;
}

.just-say-it {
    position: relative;
    top: -50px;
    left: 58px;
    right: 40px;
    width: 203px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: var(--subscrip-modal-text);
    padding: 10px 21px;
}

.just-say-it-on-thanks {
    position: relative;
    top: -175px;
    left: 40px;
    right: 40px;
    width: 190px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(29, 33, 35, 0.30);
    padding: 10px 21px;
}

.p1 {
    color: var(--white-text);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis !important;
    text-transform: capitalize;
    word-break: break-word;
}

.p2 {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.p3 {
    color: var(--white-text);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.texts-wrapper {
    margin-top: 30px;
}

.close-song-image {
    position: absolute;
    top: -180px;
    left: 20px;
    right: 20px;
}

.close-button-new-outer {
    margin-bottom: 25px;
}

.close-button-new {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50px;
    color: #000;
    cursor: pointer;
    z-index: 10;
}

.new-sub-jazztune {
    top: 50px;
    width: 368px;
    margin: auto;
    outline: none;
    padding: 48px 32px 24px;
    transform: translate(0%, 37%);
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 20px;
    // background-color: #131516;
    background-color: var(--profile-modal);
    border: var(--jazz-tune-modal-border);
    // border: 1px solid rgba(215, 25, 32, 0.20);
}

.send-otp-message {
    width: 100%;
    text-align: center;
    color: #fff;
}

.send-otp-success {
    color: green !important;
}

.send-otp-error {
    color: red !important;
}

.img-ring {
    width: 12px;
    height: 12px;
}

.light-theme {
    background-color: white;
    color: black;
}

.dark-theme {
    background-color: #131516 !important;
    color: white;
}

.dark-theme .tab-heading {
    color: var(--white-text) !important;
}

.light-theme .tab-heading {
    color: var(--white-text) !important;
}

.dark-theme .card-content {
    background-color: #131516 !important;
    padding-left: 0px;
}

.dark-theme .sidebar-tab-item {
    background: none !important;
    box-shadow: none !important;
}

.dark-theme .card-root-style {
    background-color: #131516 !important;
    cursor: pointer;
}

.dark-theme .card-root-style-artist {
    background-color: #131516 !important;
    border-radius: 100px;
    cursor: pointer;
}

.dark-theme .song-title {
    color: white !important;
}

.dark-theme .box-style {
    border-radius: 20px !important;
    // border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background: rgba(255, 255, 255, 0.05) !important;
}


.dark-theme .tabs-btn {
    color: white !important;

}

.dark-theme .MuiPaper-root {
    background-color: #131516 !important;
}

.dark-theme .search-color {
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(255, 255, 255, 0.05) !important;
    color: #FFF;
    padding-left: 38px;
}

.dark-theme .sidebar-item-mob {
    background: none !important;
    box-shadow: none !important;
}

.tabs-btn {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    opacity: 1 !important;
    padding: 0px 28px !important;
    min-height: 42px !important;
    min-width: auto !important;
    text-transform: capitalize !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 17px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 26px;
    left: 9px;
    bottom: 4px;
    background-color: bisque;
    transition: .4s;
}

.verif-main {
    height: 568px !important;
}

input:checked+.slider {
    background-color: #EF3652;
}

input:focus+.slider {
    box-shadow: 0 0 1px #EF3652;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 100%;
    top: -5px;
}


.content-min-height {
    min-height: 430px;
}

.mainslider-hm {
    margin-top: 35px;
    margin-bottom: 30px;
}

.mainslider-owl .owl-stage {
    padding-left: 0px !important;

}

.box-style {
    border-radius: 20px;
    width: 225px;
    height: 65px;
    // border: 1px solid #ccc;
    transition: border-color 0.2s;
}

.box-style.active {
    border: 1px solid #EF3652 !important;
    /* Change the border color to red when active */
}

.box-style span {
    position: absolute;
    transform: translate(17px, 22px);
}

.top-new-release {
    display: flex;
    gap: 17px;
    margin-left: 40px;
}


.main-heaing-head {
    margin-left: 40px;
    margin-top: 38px;
    margin-bottom: 25px;
}

.logout-modal-div {
    width: 260px !important;
    height: 327px !important;


}
















.ellipses-2line-text {
    text-overflow: ellipsis !important;
    word-break: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


.chip-time {
    width: 50px !important;
    height: 23px !important;
    background: rgba(63, 177, 107, 0.5) !important;
    border: 1px solid #3FB16B !important;
    border-radius: 10px !important;
    font-weight: 600;
    font-size: 10px;
    line-height: 31px;
    letter-spacing: 0.686111px;
    color: #FFFFFF;
    padding: 4px 6px 4px 5px;
}

.chip-watch-title {
    font-weight: 600;
    font-size: 15px;
    // line-height: 31px;
    // letter-spacing: 0.686111px;
    color: #FFFFFF;
}

.linear-bar {
    margin-bottom: 20px;
    margin-top: 7px;
    // border: 1px solid #3FB16B;
    height: 2px !important;
    // border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.see-all-text {
    font-weight: 500;
    font-size: 14px;
    color: #3FB16B;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}

.div-relative {
    position: relative;
    width: 157px;
    height: 116px;
}

.chp-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    object-fit: cover;
}

.chp-text span {
    position: absolute;
    bottom: 10px;
    left: 0px;
    z-index: 22;
    padding: 0px 17px;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    box-sizing: border-box;
    word-break: break-word;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.close-modalchap {
    position: absolute;
    right: 40px;
    top: 36px;
    cursor: pointer;
}

.modalseeall-headingh2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #404B63;
    margin: 0px 0px 20px;
}

///////browse cate//
.recommend-thumb {
    border-radius: 30px;
}

.main-slider-thumb {
    border-radius: 40px;
}

@media (max-width:450px) {
    .modal-mobile {
        width: 320px !important;
        height: 500px !important;
    }
}

@media (max-width:1440px) {
    .mainslider-hm {
        margin-top: 35px;
        margin-bottom: 30px;
    }
}

@media (min-width:1280px) and (max-width:1439px) {
    .close-modal {
        top: 4% !important;
    }

    .main-login-modal {
        height: 466px !important;
        padding: 20px 32px 13px !important;
        margin-top: -50px !important;
    }

    .verif-main {
        margin-top: -50px;
        height: 500px !important;
    }

    .new-sub-jazztune {
        top: 140px;
    }


    .close-song-image {
        top: -238px;
    }

    .modalImageNew {
        width: 100%;
        padding: 0px 5px;
        max-height: 270px;
        object-fit: cover;
    }

    .modal-logo-main {
        padding-top: 103px !important;
    }

    .sub-btn-yes {
        padding: 7px 32px;
    }

    .sub-btn-no {
        padding: 7px 32px;
    }

    .charge-txt {
        font-size: 8px !important;
    }

    .or-txt {
        font-size: 12px;
        margin: 0px 0px 10px;
    }

    .dial-txt {
        font-size: 9px;
        padding: 10px 13px;
    }

    .cnfrm-txt {
        font-size: 14px;
    }
}

@media (max-width:1279px) {
    .mainslider-owl .owl-stage {
        padding-left: 15px !important;
    }

    .chip-watch-title {
        font-size: 14px;
        line-height: 16px;
        display: block;
    }

    .mainslider-hm {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .main-heading-style {
        padding-right: 15px;
    }

    .main-heading-style .main-heading-style-h2 {
        font-size: 21px;
    }

    .livechange .owl-stage {
        padding-top: 0px;
        padding-bottom: 10px;
    }

    .main-heading-style {
        margin-top: 10px;
    }

    .grade-st-hm .owl-stage {
        padding-left: 43px !important;
        background: #F9F9F9;
    }

    .grade-text {
        font-size: 13.0145px;
        line-height: 23px;
    }

    .grade-mb-mar {
        margin-top: 22px;
    }

    .grade {
        padding-left: 8px;
    }

    .grade-st-hm {
        margin-bottom: 20px;
    }

    .multiline-text-title {
        font-size: 13px;
        line-height: 18px;
        margin-top: 13px;
    }

    .multiline-text-title-p {
        margin-bottom: 0px;
    }

}

@media (max-width:767px) {
    .main-heading-style {
        margin-top: 10px;
        margin-bottom: 18px;
    }

    .chip-watch-title {
        font-size: 13px;
        line-height: 16px;
        display: block;
    }

    .main-heading-style .main-heading-style-h2 {
        font-size: 18px;
    }

    .livechange .owl-stage {
        padding-top: 0px;
        padding-bottom: 10px;
    }

    .grade {
        height: 52px;
    }

    .grade-text {
        font-size: 12.0145px;
        line-height: 18px;
    }

    .grade-content {
        margin-top: 9px;
    }

    .grade-content-p {
        font-size: 12px;
        margin: 0px 0px 5px;
    }

    .grade-st-hm {
        margin-top: -7px;
        margin-bottom: 20px;
    }

    .multiline-text-title {
        font-size: 12px;
        line-height: 18px;
        margin-top: 10px;
    }

    .multiline-text-title-p {
        font-size: 11px;
        margin-top: 4px;
        margin-bottom: 0px;
    }

    .login-input-code input {
        width: 55px !important;
        height: 55px;
    }

    .charge-txt {
        font-size: 8px;
    }

    .cnfrm-txt {
        font-size: 15px;
    }
}

@media (min-width:1280px) {
    .grade-mb-mar {
        padding-left: 0px !important
    }
}

@media (min-width:1600px) {
    .main-heading-style .main-heading-style-h2 {
        font-size: 24px;
    }
}