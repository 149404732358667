.margin {
    margin-right: -5px !important;
}

.txt1 {
    // color: #FFF !important;
    color: var(--white-text) !important;
    font-size: 26px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 120% !important;
    /* 31.2px */
}

.txt2 {
    // color: #FFF !important;
    color: var(--white-text) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    /* 178.571% */
    font-family: var(--font-Urbanist) !important;

}

.footer-jazz {
    // color: #ffffff;
    color: var(--white-text);
    padding: 41px 0px 50px;
    margin-top: 30px;
    // background-color: #1C1E21;
    background-color: var(--footer-color);
}

.txt3 {
    color: #FACD66 !important;
    text-align: right !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    /* 156.25% */
}

.bg-color {
    // background-color: black;
    background-color: var(--term-condition);
}

.copyright {
    border-bottom: 1px solid rgba(215, 25, 32, 0.2);
    display: flex;
    padding: 25px 0px;
}

@media (max-width: 1199px) {
    .copyright {
        display: none;
    }
}