.sideBar {
  color: white;
  position: fixed;
  left: 19px;
  border-radius: 32px;
  width: 52px;
  height: auto;
  flex-shrink: 0;
  background: var(--dark-alt, #1A1E1F);
  position: fixed;
}

.sideBar .MuiList-padding {
  padding-top: 8px;
  padding-bottom: 0px;
}

.otp-field::placeholder {
  position: absolute;
  top: 13px;
  left: 26px;
  line-height: 50px;
  font-weight: 400;
  font-size: 40px;
  letter-spacing: 0.00039375px;
  color: #b8c0c9;
}



.map-wrapper {
  position: absolute;
  bottom: 0;
  height: 402px;
}

.add-location-div {
  width: 350px;
  position: absolute;
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 13px;
  background-color: #ffffff;
}

.add-location-heading {
  padding-left: 30px;
}

.add-location-heading p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #404b63;
}

.add-location-button {
  padding: 17px 25px 10px;
}

.location-content {
  position: relative;
  padding-left: 47px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.location-content svg {
  position: absolute;
  left: 0px;
  top: 50%;
  content: "";
  background-repeat: no-repeat;
  background-position: 0px 0px;
  transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
}

.address-div h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #404b63;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.map-animation {
  animation: slide-in 0.5s ease;
  animation-duration: 1s;
}

.addAcc-modal-close-btn {
  position: relative;
  text-align: right;
  cursor: pointer;
}

.account-heading {
  font-weight: 700 !important;
  font-size: 18px !important;
  text-align: center;
  color: #404b63;
  margin-bottom: 32px !important;
}

.Add-account-delete-btn {
  display: flex;
  justify-content: space-between;
  width: 320px;
}

.Add-account-button-same {
  background: #f3f3f5 !important;
  border-radius: 14px !important;
  padding: 10px 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  text-align: center !important;
  letter-spacing: 0.00050625px !important;
  color: #6a6b83 !important;
  height: 55px;
  width: 155px;
  text-transform: capitalize !important;
}

.Add-account-button-sameon {
  background: #3fb16b !important;
  color: #fff !important;
}

.my-element {
  // opacity: 0.5;
}

.my-element:after {
  background: #0000004d;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

.mapout-animation {
  animation: slide-out 0.5s ease;
  animation-duration: 1s;
}

@keyframes slide-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}
@media (max-width: 1279px) {
  .sideBar {
    display: none;
  }
}
@media (max-width: 1199px) {
  .login-modal {
    padding: 90px 20px 90px 20px;
  }

  .signup-heading {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .otp-field {
    width: 45px !important;
    height: 45px !important;
  }

  .add-location-div {
    top: 73%;
    bottom: auto;
  }

  .otp-field::placeholder {
    top: 8px;
    left: 14px;
  }

  .login-heading {
    font-size: 22px !important;
    line-height: 31px !important;
  }

  .login-para {
    font-size: 15px !important;
    line-height: 22px !important;
    margin-bottom: 27px !important;
  }

  .have-account-text {
    font-size: 15px !important;
    line-height: 26px !important;
  }
}