.heading-margin-btm {
    margin-bottom: 30px;
}

.paragrap-according {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #919191;
    margin-top: 0px;
}
.according-content {
  padding: 15px 21px 0px ;
}
@media (max-width:1279px) {
.heading-margin-btm {
    margin-bottom: 20px;
}
.according-content {
    padding: 5px 7px 0px;
}
.paragrap-according {
    font-size: 14px;
    line-height: 28px;
}
}