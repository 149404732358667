@import "../variables";

:root {
    --primary-color: #ff0000;
}

// :root[data-theme="light-theme"] {
//     --primary-color: green;
//     --text-color: #333333;
//     --bg-color: #fafafa;
//     --component-bg-color: #ffffff;
//     --white-text: #131516;
//     --footer-color: #F2F2F2;
//     --term-condition: #FFF;
//     --card-border-content: 1px solid transparent;
//     --table-td-color: rgba(143, 143, 143, 0.10);
//     --table-text-artist: #131516;
//     --profile-modal: #FFFFFF;
//     --player-border: 1px solid rgba(19, 21, 22, 0.10);
//     --player-bg: rgba(255, 255, 255, 0.30);
//     --player-button: #131516;
//     --jazz-tune-modal-border: transparent;
//     --subscrip-modal-text: rgba(255, 255, 255, 0.30);
//     --modal-login: #FFF

// }

:root[data-theme="dark-theme"] {
    --primary-color: #49afd9;
    --text-color: #e3f5fc;
    --bg-color: #1b2a32;
    --component-bg-color: #22343c;
    --white-text: #fff;
    --footer-color: #1A1E1F;
    --term-condition: #131516;
    --card-border-content: 1px solid rgba(0, 0, 0, 0.05);
    --table-td-color: #2B2B2B;
    --table-text-artist: #B0B8BF;
    --profile-modal: #131516;
    // --profile-modal: rgba(29, 33, 35, 0.3);
    --player-border: 1px solid rgba(255, 255, 255, 0.10);
    --player-bg: rgba(29, 33, 35, 0.9);
    --player-button: #fff;
    --jazz-tune-modal-border: rgba(215, 25, 32, 0.20);
    --subscrip-modal-text: rgba(29, 33, 35, 0.3);
    --modal-login: #131516
}

.main-heaing-head h2 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 34.918px;
    color: var(--primary-color);
}

// .light-theme .main-heaing-head h2 {
//     color: var(--primary-color) !important;
// }
// .dark-theme .main-heaing-head h2 {
//     color: var(--primary-color) !important;
// }


.tele-main-container {
    margin-top: 40px;
}

.img-full {
    width: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.d-flex {
    display: flex !important;
}

.carosul-image {
    width: 100% !important;
    object-fit: cover;
    border: 0.5px solid #F1F5F8;
    background-color: #F1F5F8;
}

.carosul-image-main {
    width: 100% !important;
    object-fit: cover;
}

.object-fit {
    object-fit: cover;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.wrap-pdd-left {
    padding-left: 36px;
}

.wrap-pdd-right {
    padding-right: 36px;
}

.border-radius22 {
    border-radius: 22px;
}

.border-radius15 {
    border-radius: 15px;
}

.text-align-center {
    text-align: center !important;
}

.owl-staging-off .owl-stage {
    padding-left: 30px !important;

}

.form-adjustment {
    background-color: white;
    box-shadow: 0px 8px 8px RGB(0 0 0/10%);

}

.cursor-pointer {
    cursor: pointer;
}

.bredcum li {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7B7B7B;
}

.bredcum li a {
    text-decoration: none;
    color: inherit;
}

.bredcum-active {
    color: #52B97A;
}

.checkbox-style {
    position: relative;
    top: -45px;
    left: -146px
}

@media (max-width:1279px) {

    .wrap-pdd-leftofff {
        padding-left: 0px !important;
    }

    // .wrap-pdd-leftoff .owl-stage {
    //     padding-left: 23px !important;
    // }

    .wrap-pdd-left {
        padding-left: 25px;
    }

    .wrap-pdd-right {
        padding-right: 25px;
    }

    .full-wrapper-sub {
        margin-top: 20px;
    }

    .owl-staging-off .owl-stage {
        padding-left: 23px !important;
    }
}

@media (max-width:767px) {
    .bredcum {
        margin-top: 20px;
    }
}