@import "src/assets/variables";
.main-filter-container {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 1000;
  background: white;
  padding: 10px;
  border: 1px solid #1B2132 !important;
  border-radius: 20px !important;
}

.filter-items {
  display: flex;
  align-items: center;
  padding: 10px 25px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  color: $primaryGreen !important;
}
