@import "../variables";
//

.skrapLogin {
  max-width: 60%;
}

.app-logo1 {
  width: 65%;
  height: 100%;
}

.app-logo2 {
  width: 45%;
  height: 100%;
}

.sidebar-handler-arrow {
  background: $white !important;
  margin-top: -35px !important;
  //padding: 8px !important;
}




.tool-bar {
  background-color: 'White';
  height: 111px;
}

.logout-container {

  .logout-option-container {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    min-height: 0px;
    height: auto;
    transition-duration: 261ms;
    position: absolute;
    padding: 10px 20px 25px 20px;
    top: 50px;
    background: #fcfafa;
    border: 1px solid #fcfafa;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
  }

  .profile-option-container {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    min-height: 0px;
    height: 471px;
    width: 333px;
    transition-duration: 261ms;
    position: absolute;
    padding: 10px 25px 25px 25px;
    background: #FFFFFF;
    border: 1px solid #fcfafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    top: 100%;
    right: 0px;
  }
}



.drop-log-profile {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
}

.dropprofile-thumbstp {
  align-items: center;
  display: flex;
}

.dropprofil-status {
  width: 60px;
  position: relative;
}

.dropprofil-status .primg {
  border-radius: 100%;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.dropprofil-status .stus-loog {
  position: absolute;
  right: -7px;
  top: -1px;
}

.dropprofil-status .stus-loog img {
  width: 20.63px;
  height: 20.48px;
}

.drop-profilenametitle {
  margin-left: 24px;
}

.drop-profilenametitle span {
  display: block;
}

.drop-profilenametitle .big-tt {
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  color: #404B63;
}

.drop-profilenametitle .smal-tt {
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
  color: #404B63;
  padding-top: 7px;
}

.drop-profile-list {
  display: flex;
  flex-flow: column wrap;
  margin-top: 11px;
  padding: 0px 10px;
}

.drop-list-item {
  border-bottom: 1px solid #E1E8ED;
  padding: 16px 0px;
  display: flex;
  align-items: center;
}

.drop-list-item .drp-icon-left {
  margin-right: 15px;
}

.drop-list-item .drp-icon-right {
  margin-left: auto;
}

.drop-list-item span {
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: #3F4A62;
}

.drop-logout-btn {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #FFFFFF !important;
  mix-blend-mode: normal;
  opacity: 0.84;
  background: #252b39 !important;
  -webkit-backdrop-filter: blur(13.5914px);
  backdrop-filter: blur(13.5914px);
  border-radius: 9px !important;
  padding: 4px 16px !important;
  text-align: center;
  text-transform: capitalize !important;
  vertical-align: middle;

  & .droplog-arrow {
    margin-right: 10px;
    display: inline-flex;

  }
}

.logout-link {
  color: $primaryGreen !important;
  text-decoration: none;
  background-image: linear-gradient($primaryColor, $primaryColor, $primaryColor);
  background-size: 20px 2px, 100% 2px, 0 2px;
  background-position: calc(20px * -3) 100%; // to change transition direction multiply with +ve no.
  background-repeat: no-repeat;
  transition: background-position 1s linear, background-size 1s linear;
  font-size: 20px;
  //text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;

  &::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    //color: $primaryColor !important;
    //font-size: 23px;
    background-size: 320px 2px, 0 2px, 100% 220px;
    background-position: calc(100% + 20px) 100%, 100% 100%, 100%;
  }
}

.person-icon {
  font-size: 50px !important;
  margin-left: -10px;
  //color: $white;
  //background-color: $dividerColor;
  //border-radius: 50px;
}

.user-profile-icon {
  width: 50px !important;
  height: 50px;
}

.profile-bar-top {
  display: flex;
  margin-left: auto;
  // background: rgba(255, 255, 255, 0.05);
  // border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 35px;
  align-items: center;
  padding-left: 25px;
  cursor: pointer;
}

.profile-bar-top .icon-pro {
  margin-right: 26px;
  padding-top: 6px;
}

.profile-outer-btn {
  border-radius: 30px;
  border: 0.945px solid rgba(255, 255, 255, 0.05);
  background: linear-gradient(146deg, #22283E 9.7%, #171827 86.02%);
  box-shadow: 3.78px 3.78px 9.45px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 5px;
}

.profileimg-state {
  border: 0.945px solid rgba(255, 255, 255, 0.05);
  background: linear-gradient(146deg, #22283E 9.7%, #171827 86.02%);
  box-shadow: 3.78px 3.78px 9.45px 0px rgba(0, 0, 0, 0.2);
  padding: 1px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
}

.phn-no {
  font-family: "Syne", sans-serif !important;
}

.login-btn-profile {
  width: 80px;
  padding: 11px;
  border-radius: 20px;
  background: #FFF;
  color: #14141F;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border: none;
  box-shadow: 3.78px 3.78px 9.45px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.content-wraper {
  background: #fff;
}

.drawerOpen-style {
  color: white;
  border-right: 1px solid #14141F !important;
  background-color: #FAFAFA !important;
}

.dark-theme .drawerOpen-style {
  color: white;
  border-right: 1px solid #14141F !important;
}

.srch-right {
  display: flex;
  margin-left: auto;
}

.sidebar-container {
  flex-flow: row wrap;
  margin-left: 96px;
  min-height: 900px;

  div {
    .MuiDrawer-paper {
      //top:65px !important;
      height: 100% !important;
      //border-bottom-right-radius: 35px;
      height: 100% !important;
      background: transparent;
    }

    .MuiPaper-elevation4 {
      box-shadow: none !important;
      margin-top: 10px;
    }

    .makeStyles-drawerOpen-7 {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
      //border-top-right-radius: 35px;
      //padding-top: 25px;

    }
  }
}

.dark-theme .sidebar-container div .MuiDrawer-paper {
  border-right: 1px solid #14141F !important;
}

.MuiDrawer-paper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19) !important;
  //border-top-right-radius: 35px;
  //padding-top: 25px;

  .MuiPaper-elevation0 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19) !important;
  }
}

//.MuiPaper-elevation0 {
//  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19) !important;
//}

.current-user {
  span {
    font-size: 15px;
    text-transform: capitalize;
    //color: $white !important;
    padding-left: 5px;
    font-weight: 600;
  }
}

.sidebar-tab-icon {
  // min-width: 30px !important;

  img {
    width: 24px;
    height: 24px;
    margin-right: 22px;
  }
}

.sidebar-tab-icon svg {
  height: 25px;
  width: 24px;
  margin-left: 0px;
}

.sidebar-tab-logo {
  Width: 94px;
  Height: 102px;

  // img {
  //   width: 24px;
  //   height: 24px;
  //   margin-right: 22px;
  // }
}

.sidebar-tab-item {
  width: 221px;
  height: 48px;
  padding-left: 15px !important;
  background: #FFFFFF !important;
  border-left: 1px solid rgba(63, 74, 98, 0.16);
  box-shadow: 4px 4px 4px rgba(63, 177, 107, 0.1);
  padding-right: 15px !important;
}

.sidebar-tab-item i {
  color: #3FB16B;
  background-color: #3FB16B;
}

.sidebar-tab-item .MuiListItemText-primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #EF3652;
  // color: #404B63;
}

.sidebar-logospacing {
  margin: 20px auto 30px;
  text-align: center;
}



.sidebar-tab-spacing {
  margin: 0px 0px 12px 0px !important;
  height: 48px !important;
  padding: 0px 14px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.sidebar-tab-text {
  text-transform: capitalize;
  //color: $primaryBlack !important;
  color: #7c8593 !important;
  //padding: 10px  !important;

  div {
    .MuiTypography-body1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #404B63;
    }
  }

  //&:active {
  //  background-color: $white !important;
  //  color: $primaryBlue;
  //  //padding: 10px 0 !important;
  //  //margin: 0 5%;
  //}
}

.top-header-bar {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  align-items: center;
}

.sider-first-spac {
  width: 229px;
}


.sider-top-mainsearch {
  /* position: absolute; */
  /* right: 0px; */
  display: flex;
  align-items: center;


}

// .search-formbar {
//   width: 474px;
//   display: flex;
//   padding: 0px;
//   align-items: center;
//   position: relative;
// }
.search-formbar {
  /* right: 108px; */
  /* width: 497px; */
  /* position: absolute; */
  /* top: -9px; */
  position: relative;
}

.buttonsearch {
  position: absolute !important;
  right: 5px;
  padding: 0px !important;
}

.topbar-right-log {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.loginbutton-topbar {
  color: #FFFFFF !important;
  font-size: 16px !important;
  text-align: center !important;
  line-height: 21px !important;
  border-radius: 16px !important;
  letter-spacing: 0.00050625px !important;
  background-color: #3FB16B !important;
  padding: 15px 27px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.search-svg-adjust {
  position: absolute;
  left: 12px;
  top: 15px;
}

.mobile-logo-topbar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: 72px;
  padding: 10px 20px;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.02);
  background: #fff;
}

.tpbar-logomobile {
  margin-right: auto;
}

.tpbar-logomobile img {
  width: 48px;
}

.tpbar-searchmobile button {
  padding: 0px;
}







.active-tab {
  display: block;
  padding: 6px 0px 6px 10px !important;
  background: #FFFFFF !important;
  border-left: 1px solid rgba(63, 74, 98, 0.16);
  box-shadow: 0px 11px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px 0px 0px 6px;
  // border-radius: 0px 35px 35px 0px !important;
  //margin: 7px 0 ;
  width: auto !important;
  //background-color: $primaryBlue !important;
  //border-radius: 25px 25px !important;
  color: #EF3652 !important;
  //border-left: 5px solid $primaryBlue !important;
  //margin: 0 6% !important;
  text-transform: capitalize;
  left: 15px;
  //padding: 5px 15px !important;

  div {
    .MuiTypography-body1 {
      font-weight: 500 !important;
      font-size: 16px;
      // font-family: 'IBM Plex Sans';
    }
  }
}

.sidebar-tab-item .sidebar-tab-icon svg path {
  fill: #EF3652;
}

.black_icon {
  filter: brightness(0) invert(60%);
}

.white_icon {
  //filter: brightness(58%) invert(50%);
  color: $primaryGreen;
}

.profile-avatar {
  img {
    width: 70% !important;
    height: auto !important;
  }
}

.sidebar-signout-btn {
  background: transparent;
  border: none;
  outline: none;
  padding-left: 0;
  font-weight: 600;
  cursor: pointer;
}

.MuiIconButton-root {
  background: transparent !important;
  border-radius: 0 !important;
  //height: 80px !important;
}

#root .makeStyles-content-16 {
  top: 100px !important;
}

.custom-btn {
  width: 140px;
  height: 30px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 35px;
  padding: 7px 25px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  //box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
  //7px 7px 20px 0px rgba(0, 0, 0, .1),
  //4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.btn-3 {
  background: $primaryGreen;
  background: linear-gradient(0deg, $primaryGreen 0%, $primaryGreenLight 100%);
  width: 140px;
  height: 30px;
  line-height: 31px;
  padding: 0;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 35px;
}

.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: $primaryGreen;
  transition: all 0.3s ease;
  border-radius: 35px !important;
}

.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: $primaryGreen;
  transition: all 0.3s ease;
  border-radius: 35px;
}

.btn-3:before {
  height: 0%;
  width: 2px;
  border-radius: 35px !important;
}

.btn-3:after {
  width: 0%;
  height: 2px;
  border-radius: 35px !important;
}

.btn-3:hover {
  background: transparent;
  box-shadow: none;
  border-radius: 35px !important;
}

.btn-3:hover:before {
  height: 100%;
}

.btn-3:hover:after {
  width: 100%;
  border-radius: 35px;
}

.btn-3 span:hover {
  color: black;
  border-radius: 35px;
}


.btn-3 span:before {
  width: 2px;
  height: 0%;
  border-radius: 35px;
}

.btn-3 span:after {
  //width: 0%;
  height: 2px;
  border-radius: 35px;
}

.btn-3 span:hover:before {
  height: 100%;
  border-radius: 35px;

}

.btn-3 span:hover:after {
  width: 100%;
  border-radius: 35px;
}

.colorGreen {
  color: red !important;
}

.colorGreen {
  img:hover {
    filter: hue-rotate(100deg) !important;
  }
}
.phn-noo{
  color: var(--white-text) !important;

}
.mobile-menubar-outer {
  position: fixed;
  bottom: 10px;
  width: auto;
  display: flex;
  justify-content: center;
  padding-left: 25px;
  background: #FFFFFF;
  box-shadow: 0px -1px 4px rgba(62, 176, 107, 0.1);
  z-index: 20;
  margin: auto;
  left: 50%;
  border-radius: 79px;
  transform: translate(-50%, 0px);
  -webkit-transform: translate(-50%, 0px);
  border: 1px solid rgba(79, 82, 79, 0.32);
}

.mobile-menu-bar {
  display: flex;
  justify-content: space-between;
}

.mobile-menu-inner {
  display: flex !important;
}

.sidebar-item-mob {
  height: 52px !important;
  width: 52px !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 100px !important;

  div {
    .MuiTypography-body1 {
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
    }
  }
}

.sidebar-tab-icon-mob {
  justify-content: center;
}

.sidebar-tab-icon-mob svg {
  height: 18px;
  width: 18px;
}

.active-tab-mob {
  background: #FFFFFF !important;
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.0891882) !important;
  color: #EF3652 !important;
}

.active-tab-mob .sidebar-tab-icon-mob svg path {
  fill: #EF3652;
}

////////music card/////
.card-content {
  // border: 1px solid rgba(0, 0, 0, 0.05);
  border: var(--card-border-content);
  background: #FFF;
  // box-shadow: 18.21429px 18.21429px 122px 0px rgba(162, 89, 255, 0.10);
  border-radius: 0px 0px 20px 20px;
}

.card-content-artist {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 20px 20px;
  color: #FFF !important;
  text-align: center !important;
  font-family: Syne !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.card-content-artist-txt {
  // color: #FFF !important
  color: var(--white-text) !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.artist-img {
  width: 150px;
  /* height: 190px; */
  border-radius: 100px;
  margin-bottom: 15px;
  cursor: pointer;
}

.song-title {
  color: #191926 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.singer-title {
  color: #9CA3AF !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  font-family: var(--font-Urbanist) !important;

}

.batch-style {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.60);
}

.batch-style span {
  font-size: 11px;
  font-weight: 700;
  padding: 0px 36px 0px 40px;
  text-align: center;
}

.tab-heading {
  margin-bottom: 20px !important;
  margin-top: 35px !important;
  color: #191926 !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: -1px !important;
  font-family: "Syne", sans-serif !important;
}

.sider-top-srch-mobs {
  display: none;
}

.table-responsive-search {
  padding-right: 25px;
}

.sideBar ul div:last-child {
  display: none;
}

@media(max-width:1279px) {
  .logout-container {
    .profile-option-container {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      min-height: 0px;
      height: 100%;
      width: 100%;
      transition-duration: 261ms;
      position: fixed;
      padding: 10px 25px 25px 25px;
      background: #FFFFFF;
      border: none;
      box-shadow: none;
      border-radius: 0px;
      top: 0px;
      right: auto;
      left: 0px;
      z-index: 18;
    }
  }

  .sidebar-container {
    margin-left: 0px;
    padding-top: 20px;
  }

  .mobile-menubar-outer .sidebar-tab-spacing {
    margin: 0px 28px 0px 0px !important;
    height: 48px !important;
    padding: 0px 14px !important;
  }

  .mobile-menubar-outer .MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    min-width: auto;
    flex-shrink: 0;
  }

  .tab-heading {
    font-size: 22px !important;
    padding-left: 15px;
  }

  .drop-profilenametitle {
    margin-left: 0px;
    margin-top: 17px;
    margin-bottom: 10px;
  }

  .drop-profilenametitle .big-tt {
    font-size: 25px;
    display: block;
    margin-bottom: 12px;
  }

  .drop-profilenametitle .smal-tt {
    font-size: 15px;
    display: block;
  }

  .dropprofil-status {
    width: 134px;
  }

  .dropprofil-status .primg {
    height: 134px;
    width: 134px;
  }

  .dropprofile-thumbstp {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .sider-top-srch-mobs {
    display: block;
    padding: 0px 15px;
  }

  .search-formbar {
    width: 100%;
  }

  .mobile-search-bar {
    width: 100% !important;
  }

  .table-responsive-search {
    padding: 0px 15px;
  }

  .tabs-search-car {
    padding-left: 15px;
  }

  .headerTxt1 {
    font-size: 19px !important;
  }
}