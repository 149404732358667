*,
*::before,
*::after {
    box-sizing: border-box;
}
:root {
    --font-Urbanist:'Urbanist', sans-serif !important;
}
html,
body {
    height: 100%;
}

body {
    margin: 0 !important;
    /* font-family: 'IBM Plex Sans', sans-serif !important; */
    font-family: 'Syne', 'sans-serif' !important;
    font-style: normal;
}

.MuiTypography-body1 {
    /* font-family: 'IBM Plex Sans', sans-serif !important; */
    font-family: 'Syne', 'sans-serif' !important;

}

.MuiTypography-body2 {
    /* font-family: 'IBM Plex Sans', sans-serif !important; */
    font-family: 'Syne', 'sans-serif' !important;

}
.MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h6, .MuiTypography-h5 {
    font-family: 'Syne', 'sans-serif' !important;

}
.MuiAppBar-colorPrimary {
    background-color: #1B2132 !important;
}

.MuiButton-textPrimary {
    color: #1B2132 !important;
}

/* @font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./fonts/glyphicons-halflings-regular.ttf) format('truetype');
} */

/* @font-face {
    font-family:"Syne", sans-serif;
    src: local('Lato'), url(./fonts/RobotoCondensed-Regular.ttf) format('opentype');
    src: local('Lato'), url('./fonts/RobotoCondensed-Regular.ttf') format('opentype');
} */
