.watch-vid-main-container {
    // margin-top: 50px;
    // margin-bottom: 80px;
    margin: 40px;
}

// .watch-vid-spacing {
//     padding: 0px 36px;
//     margin-top: 36px;
// }

.watch-vid-spacing {
    padding: 0px 36px;
    margin-top: 36px;
    margin-bottom: 34px;
}

.wtchvid-main-img {
    height: 619px;
    // margin-bottom: 25px;
    background: #D9D9D9;
    border-radius: 28px;
    object-fit: cover;
}

.walkthrough-padding {
    padding: 0px 26px
}

.walkthrogh-uper-line1 {
    width: 100%;
    height: 52px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    position: relative;
    left: 0px;

}
.walkthrogh-uper-line2 {
    width: 100%;
    height: 52px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    position: relative;
    left: 0px;

}
.walkthrogh-uper-line3 {
    width: 100%;
    height: 52px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    position: relative;
    left: 0px;

}

.wtchvid-main-card {
    background-color: transparent !important;
    box-shadow: none !important;
}

.wtchvid-main-card h1,
.wtchvid-main-card h2,
.wtchvid-main-card h3,
.wtchvid-main-card h4,
.wtchvid-main-card h5,
.wtchvid-main-card h6 {
    margin-top: 0px;
    margin-bottom: 16px;
}

.wtchvid-main-card p {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
}

.wtchvid-main-card h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: normal;
    color: #252D40;
}

.wtchvid-main-card h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 20px;
}

.wtchvid-list-box {
    padding: 46px 45px 12px;
    background: #FAFAFA;
    border: 1px solid #E1E8ED;
    border-radius: 28px !important;
}

.wtchvid-content-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.wtchvid-multileheading-h1 {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 31px !important;
    color: #404B63;
    margin-bottom: 0px !important;
}

// .wtchvid-multileheadingsm-h3 {
//     font-weight: 600;
//     font-size: 15px;
//     line-height: 20px;
//     color: #161616;
//     // -webkit-line-clamp: 2;
// }
.wtchvid-multileheadingsm-h3 {
    font-weight: 600 !important;
    font-size: 15px ! important;
    line-height: 20px ! important;
    color: #161616;
}

.wtchvid-multiletxtsm-p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 2px;
}

.watchchneel-scrollheight {
    height: 423px !important; //523px
}

.wtchvid-imagesm {
    background: #D9D9D9;
    border-radius: 28px;
    height: 88px;
    width: 88px;
    object-fit: cover;
    // border: 1px solid red;
}

.wtchvid-mr-right {
    margin-right: 30px;
}

.wtchvid-paddingsm-bottom li {
    align-items: center;
    padding: 12px 0px;
    border-bottom: 1px dashed #E1E8ED;
}

.wtchvid-paddingsm-bottom li:last-child {
    border-bottom: none;
}

.wtchvid-main-heading {
    display: flex;
    justify-content: space-between;
}

.wtchvid-poster-overlay {
    background: linear-gradient(180deg, rgba(62, 176, 107, 0.11) 0%, rgba(62, 176, 107, 0.76) 100%);
    // background: linear-gradient(transparent -10%,#19365f 95%);
    border-radius: 0 0 28px 28px;
    bottom: 333px;
    box-sizing: border-box;
    left: 0;
    // padding: 0 23px 20px;
    padding: 0 23px 304px;
    position: relative;
    width: 100%;
}

.playerWrapper {
    width: 100%;
    height: 510px;
    position: relative;
    margin-bottom: 25px;
}

.playerWrapper,
.react-player video {
    border-radius: 28px;
}

.react-player video {
    object-fit: cover;
}

.time-vid-duration {
    display: flex;
    align-items: center;
}

.time-vid-duration span:first-child {
    height: 21px;
}

.wtchvid-time-dur {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1C2238;
    margin-left: 10px;
}

.wtchvid-divider-mrgn {
    margin-top: 48px;
    margin-bottom: 0px;
}

.wtchvid-divider-mrgn hr {
    background-color: #E1E8ED;
    ;
}

.more-like-main-hdng {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.686111px;
    color: #3F4A62;
    margin-bottom: 24px;
}

.more-like-title {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.686111px;
    color: #3F4A62;
    line-height: 18px;
    margin-top: 13px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

a {
    text-decoration: none;
}

.more-like-text-p {
    font-weight: 400;
    font-size: 12px;
    color: #919191;
    margin-top: 7px;
    margin-bottom: 7px;
}

.more-like-img {
    border-radius: 15px;
}

.more-like-text-wtchvid-timedur {
    font-weight: 400;
    font-size: 12px;
    color: #161616;
    position: relative;
    top: -3px;
    margin-left: 10px;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%
        /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: relative;

    &:after {
        background: linear-gradient(180deg, rgba(62, 176, 107, 0.11) 0%, rgba(62, 176, 107, 0.76) 100%);
        border-radius: 0 0 28px 28px;
        bottom: 0px;
        box-sizing: border-box;
        left: 0;
        padding: 0 23px 304px;
        position: absolute;
        width: 100%;
        content: '';
    }
}

.react-player.active:after {
    display: none;
}

.play-icon-btn {
    position: absolute;
    width: 112px;
    height: 112px;
    z-index: 1;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

/** Walkthrough */
.wlkthrough-modal {
    position: relative !important;
    background-color: #FFFFFF !important;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 8px 8px RGB(0 0 0/10%) !important;
    box-sizing: border-box !important;
    // border-radius: 20px !important;
    padding: 25px 0px 25px !important;
    // min-height: 812px;
}

.cross-pointer {
    display: flex;
    flex-flow: row-reverse;
    margin-bottom: 10px;
    padding-right: 26px;
}

.wlkthrough-img {
    width: 376px !important;
    height: 440px !important;
}

.wlkthrough-heading {
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 39px !important;
    color: #404B63 !important;
    margin: 28px 0px 0px 0px !important;
}

.vedio-walk-through {
    width: 100%;
    height: 178px;
    background: rgba(63, 177, 107, 0.04);
    border-radius: 20px;
}

.vedio-walk-through-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #404B63;
    margin: 0px 0px 18px;
}

.vedio-walk-through-text-list {
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: #808080;
    margin: 0px;
}

.main-div-vedio {
    text-align: left;
}

.vedio-list {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    text-align: left;
}

.vedio-list li {
    position: relative;
    padding-left: 25px;
}

.vedio-list li span {
    position: absolute;
    left: 0px;
    top: 9px;
}

.vedio-list li span img {
    width: auto !important;
}

.vedio-walk-through .react-player video {
    border-radius: 20px !important;
}

.wlkthrough-desc {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: 0.00045px !important;
    color: #808080 !important;
    margin: 29px 4px 0px !important;
}

.wlkthrough-login-now {
    font-weight: 700 !important;
    font-size: 13.9888px !important;
    line-height: 18px !important;
    color: #B8C0C9 !important;
}

.wlkthrough-login-now span {
    margin-top: 2px !important;
    position: absolute !important;
}

.wlkthrough-btn {
    font-family: 'IBM Plex Sans' !important;
    text-transform: none !important;
    background: #3FB16B !important;
    border-radius: 14px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    padding: 12px 40px !important;
    letter-spacing: 0.00050625px !important;
    color: #FFFFFF !important;
    margin: 25px 0px 17px 0px !important;

    &:hover {
        background-color: #3FB16B !important;
    }
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 20px;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .active {
        mix-blend-mode: normal !important;
        opacity: 0.7 !important;
        border: 1px solid #3FB16B !important;
        border-radius: 30px !important;
        width: 16px !important;
        height: 16px !important;
    }
}

.owl-dot span {
    background: #071827 !important;
    width: 4px !important;
    height: 4px !important;
    margin: 5px 7px 6px 6px !important;
    mix-blend-mode: normal !important;
    opacity: 0.5 !important;
}

.owl-dot.active span {
    background: #3FB16B !important;
    width: 6px !important;
    height: 6px !important;
    margin: 4px 7px 5px 4px !important;
    opacity: 1 !important;
}

.spacing-both-mobile-head {
    padding: 0px 25px;
    margin-top: 10px;
}

.mobile-heading-border-box {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 20px 0px 20px;
}

.mobile-heading-border-box:after {
    content: '';
    position: absolute;
    left: 0px;
    background: #3FB16B;
    height: 3px;
    width: 86px;
    border-radius: 5px;
    top: 98%;
}

.mobile-heading-border-box-h1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 31px;
    color: #1C2238;
    margin: 0px;
    padding-bottom: 7px;
}

.text-modal-hdng {
    text-align: center;
    margin-bottom: 15px;
}

.share-icn-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

@media (max-width: 1440px) {

    .playerWrapper,
    .wtchvid-main-img {
        height: 455px;
    }

    .watchchneel-scrollheight {
        height: 372px !important;
    }

    .wtchvid-list-box {
        padding: 29px 25px 20px;
    }

    .wtchvid-mr-right {
        margin-right: 14px;
    }

    .wtchvid-multileheading-h1 {
        font-size: 21px !important;
        line-height: 31px !important;
        margin-bottom: 0px !important;
    }
}

@media (max-width: 1279px) {
    .playerWrapper {
        margin-bottom: 10px;
    }

    .playerWrapper,
    .wtchvid-main-img {
        height: 455px;
    }

    .react-player:after {
        padding: 0 23px 174px;
    }

    .playerWrapper,
    .react-player video,
    .wtchvid-main-img {
        border-radius: 0px;
    }

    .play-icon-btn {
        width: 92px;
        height: 92px;
    }

    .wtchvid-main-card h3 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 0px;
        line-height: 28px;
    }

    .wtchvid-main-card p {
        margin-bottom: 15px;
        font-size: 14px;
    }

    .wtchvid-divider-mrgn {
        margin-top: 18px;
        margin-bottom: 0px;
    }

    .watch-vid-spacing {
        padding: 0px 36px;
        margin-top: 36px;
        margin-bottom: 34px;
    }

    .watch-vid-spacing .video-gridfull.MuiGrid-spacing-xs-2 {
        width: calc(100% + 0px);
        margin: 0px;
    }

    .watch-vid-spacing .video-gridfull.MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0px;
    }

    .watch-vidheading-space {
        padding: 0px 25px;
        margin-top: 20px;
    }

    .more-like-title {
        font-size: 13px;
        line-height: 18px;
        margin-top: 13px;
    }

    .more-chp-left {
        min-width: 80px;
        flex-shrink: 0;
    }

    .more-chp-right {
        background: #F1F5F8;
        border-left: 2px dashed #63BF87;
        border-radius: 0px 20px 20px 0px;
        padding-left: 20px;
        height: 80px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-flow: column wrap;
        max-width: 100%;
        align-items: self-start;
        flex-grow: 0;
        justify-content: center;
        flex: 1 1 auto;
    }

    .more-chp-right .more-like-title {
        font-size: 13px;
        font-weight: 700;
        line-height: 17px;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .more-chp-right .more-like-text-p {
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .ch-mor-mbcolumn {
        display: flex;
        flex-flow: row;
        height: 80px;
        margin-bottom: 16px;
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }

    .more-like-img-mobile {
        border-radius: 15px;
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 20px 0px 0px 20px;
    }

}

@media (max-width: 1199px) {

    .playerWrapper,
    .wtchvid-main-img {
        height: 500px;
    }

    .wtchvid-list-box {
        padding: 16px 25px 0px;
        margin-top: -10px;
        margin-bottom: 20px !important;
    }

    .watch-vid-main-container {
        margin: 30px;
    }

}

@media (max-width: 991px) {
    .watch-vid-main-container {
        margin: 25px;
    }

    .playerWrapper,
    .wtchvid-main-img {
        height: 500px;
    }

    .watch-vid-spacing {
        padding: 0px 0px;
        margin-top: 0px;
        margin-bottom: 34px;
    }

    .react-player {
        &:after {
            border-radius: 0px;
        }
    }

}

@media (max-width: 767px) {
    .watch-vid-main-container {
        margin: 0px 25px;
    }

    .playerWrapper,
    .wtchvid-main-img {
        height: 340px;
    }

    .react-player:after {
        padding: 0 23px 100px;
    }

    .play-icon-btn {
        width: 72px;
        height: 72px;
    }

    .wtchvid-list-box {
        padding: 20px 25px 10px;
        margin-top: -10px;
        margin-bottom: 20px !important;
    }

    .wtchvid-multileheading-h1 {
        font-size: 20px !important;
        line-height: 38px !important;
        margin-bottom: 0px !important;
    }

    .wtchvid-main-card h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 3px;
        margin-top: 0px;
    }

    .wtchvid-main-card p {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .wtchvid-time-dur {
        font-size: 14px;
    }

    .owl-theme .owl-dots {
        top: 302px;
    }

    .more-like-title {
        font-size: 12px;
        line-height: 18px;
        margin-top: 10px;
    }

    .watch-vid-spacing {
        padding: 0px 0px;
        margin-top: 0px;
        margin-bottom: 34px;
    }

    .more-like-text-p {
        font-size: 11px;
        margin-top: 4px;
        margin-bottom: 7px;
    }

    .react-player {
        &:after {
            border-radius: 0px;
        }
    }
    .wlkthrough-modal {
        padding: 14px 0px 25px !important;
    }
    .wlkthrough-heading {
        font-size: 22px !important;
        line-height: 33px !important;
        margin: 13px 0px 0px 0px !important;
    }
    .vedio-walk-through-text {
        font-size: 13px;
        line-height: 21px;
        margin: 0px 0px 10px;
    }
    .vedio-walk-through-text-list {
        font-size: 14px;
        line-height: 24px;
        margin: 0px;
    }
    .vedio-list li span {
        top: 6px;
    }
    .wlkthrough-btn {
        font-size: 16px !important;
        padding: 10px 32px !important;
        margin: 17px 0px 15px 0px !important;
    }
    .wlkthrough-desc {
        font-size: 15px !important;
        line-height: 22px !important;
        margin: 13px 4px 0px !important;
    }
    .walkthrough-img-adjust {
        width: 90% !important;
        margin: 0px auto;
    }


}