.login-body {
    background: #14141F;
    width: 100%;
    height: 100vh;
}

.grid-login-ev {
    height: 100vh;
}

.login-modal-outer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    flex-flow: column;
}

.logo-login {
    margin-bottom: 45px;
    margin-top: 0px;
}

.login-modal-bajao {
    width: 469px;
    border-radius: 30px;
    border: 1px solid rgba(176, 184, 191, 0.2) !important;
    background: rgba(18, 15, 22, 0.3);
    padding: 60px 64px 60px;
    position: relative;
}

.bajao-cross-btn-login {
    position: absolute;
    right: -26px;
    top: -21px;
    cursor: pointer;
}



.btn-bajao-login {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.socials-btn-align {
    position: relative;
}

.socials-btn-style {
    position: absolute;
    right: 5px;
    top: -2px;
}

.btn-bajao-login button {
    cursor: pointer;
    height: 49px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #FFF;
    font-size: 13px;
    font-weight: 500;
    line-height: 35px;
    background: transparent;
    margin-bottom: 20px;
}

.btn-bajao-login .gm-btn {
    border: 1px solid #FFF;
    background: #FFF;
    color: #10101A;

}

.btn-bajao-social {
    display: flex;
    justify-content: center;

}


.btn-bajao-login .fb-btn {
    background: #4267B2;
    border: 1px solid #4267B2;

}

.logon-sign-user p {
    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    line-height: 13px;
    margin: 0px;

}

.logon-sign-user p span {
    color: red;
    font-size: 16px;
    font-weight: 500;
    line-height: 13px;
}

.bajao-text-head h2 {
    color: #FFF;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 129%;
    /* 43.86px */
    text-transform: capitalize;
}

.bajao-text-head span {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    /* 156.25% */
}

.create-account-logo {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;

}

.login-modal-bajao h2 {
    color: #FFF;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 129%;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.input-adorment {
    padding: 12px 7px 13px 12px;
    ;
    background: rgba(255, 255, 255, 0.05);
    height: 41px !important;
    border-radius: 20px;
}

.input-adorment span {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 129%;
    text-transform: capitalize;
    margin-left: 5px !important;

}

.verify-text-btn p {
    color: #B0B8BF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 129%;
}

.verify-text-btn button {
    border-radius: 14px;
    background: #FFF;
    border: none;
    color: #10101A;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 30px;
    text-transform: capitalize;
}

.otp-form {
    margin-bottom: 40px;
}

.otp-field-focused-bajao {
    border-radius: 14px !important;
    border: 1px solid rgba(239, 54, 82, 0.60) !important;
}

.otp-field-bajao {
    width: 68.66px !important;
    height: 55px !important;
    color: #FFF !important;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 129%;
    text-transform: capitalize;
    background: #171723 !important;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    opacity: 0.75;
    margin-right: 0px !important;
    outline: none;
}

.otp-container-bajao {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
}

.verify-digit-text {
    color: #B0B8BF;
    text-align: center;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 129% !important;
    margin-bottom: 25px !important;
}

.resend-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.00045px;
    color: #808080;
}

.resend-link-button {
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.00045px;
    text-decoration: underline !important;
    color: #404b63 !important;
}

.verify-btn {
    border-radius: 14px;
    background: #FFF;
    color: #10101A;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 41px;
    border: none;
}

.active-card-design {
    border-radius: 10px;
    border: 1px solid rgba(239, 54, 82, 0.60);
    padding: 10px 10px;
}