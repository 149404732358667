.bredcrum-wraper-space {
    position: relative;
    padding: 18px 0px;
    margin: 0px !important;
    z-index: 1;
}

.info-collects h3 {
    font-weight: 500;
    font-size: 13px;
    line-height: 31px;
    text-transform: uppercase;
    color: #52B97A;
    margin: 0px;
}

.priv-policy-head {
    margin: 10px 0px 10px;
}

.priv-policy-head h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin: 0px;

}

.main-heading h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin: 5px 0px 10px;
}

.main-heading h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    letter-spacing: 0.686111px;
    color: #404B63;
    margin: 5px 0px 10px;
}

.privacy-paragraph p {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #919191;
    margin-top: 0px;
    margin-bottom: 20px;
}

.privacy-org-list {
    list-style-type: none;
    padding: 0;
    margin: 0px;
}

.privacy-org-list li {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #919191;
    margin-bottom: 10px;
    padding-left: 26px;
    position: relative;
}

.privacy-org-list li span {
    left: 0px;
    position: absolute;
    top: 0px;
}

.spacfer-pppage {
    margin-bottom: 100px;
}

@media (max-width: 1279px) {
    .priv-policy-head h2 {
        font-size: 21px;
        line-height: 27px;
    }

    .main-heading h2 {
        font-size: 21px;
        line-height: 28px;
        margin: 5px 0px 7px;
    }

    .privacy-paragraph p {
        font-size: 13px;
        line-height: 28px;
        margin-top: 0px;
        margin-bottom: 15px;
    }

    .privacy-org-list li {
        font-size: 13px;
        line-height: 28px;
        margin-bottom: 10px;
        padding-left: 24px;
    }

    .spacfer-pppage {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .priv-policy-head {
        margin: 0px 0px 10px;
    }

    .priv-policy-head h2 {
        font-size: 19px;
    }

    .main-heading h2 {
        font-size: 18px;
    }
}